<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="CATEGORIES"
          :breadcrumb="[
            {
              label: 'Dashboard',
            },
            {
              label: 'Masters',
            },
            { label: 'Categories' }
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
     
            >
            <div class="card-body">
                <div class="row">
                  <div class="col text font">

                    <h4>
                      <b style="color: #00364f; font-weight: bolder">Categories </b>
                    </h4>
                  </div>
                  <div class="col text-end ">
                    <button
                    title="Add New"
                      @click="newHeading"
                      class="btn text-white btn-sm"
                      style="background-color: red"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <b>Add New</b>
                    </button>
                    <!-- &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button> -->

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>






                <!-- start list area  -->
                <div class="row" style="margin-top:5px">
                
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>&nbsp;&nbsp;+&nbsp;&nbsp;</th>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Description</th>

                          <th>Link</th>
                          <th>Status</th>
                          <th class="text-end">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(category, index) in categories"
                            :key="index"
                          >
                            <td class="text-start">
                              <img
                                style="
                                  width: 30px;
                                  height: 30px;
                                  margin-right: 5px;
                                  border-radius: 5px;
                                "
                                :src="logofinder(category)"
                                alt=""
                              />
                            </td>

                            <td class="text-truncate">{{ category.code }}</td>
                            <td class="text-truncate">{{ category.name }}</td>

                            <td class="text-truncate">{{ category.description }}</td>
                            <td class="text-truncate">
                              <button
                              title="Services"
                                type="button"
                                @click="getServices(category.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#categoryServices"
                                class="btn text-white btn-sm"
                                style="
                                  background-color: rgb(242 19 1);
                                  padding: 5px 7px 5px 7px;
                                "
                              >
                                <b>
                                  <font-awesome-icon icon="link" />
                                </b>
                              </button>
                            </td>
                            <td class="text-truncate">
                              <p
                                v-if="category.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                            <td class="text-truncate text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <!-- @click="designationEdit(designation)" -->
                                <button
                                title="Edit"
                                  type="button"
                                  class="btn btns btn-sm btn-success"
                                  @click="categoryEdit(category)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>

                                <button
                                  type="button"
                                  class="btn btns btn-sm btn-danger"
                                  @click="categoryDelete(category)"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="trash" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadCategories(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadCategories(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadCategories(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadCategories(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadCategories(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadCategories(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadCategories(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadCategories(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadCategories(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadCategories( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadCategories( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadCategories(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadCategories(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadCategories(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadCategories(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadCategories(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modaltitle" style="color: #00364f">
              <b>Category</b>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <!-- @submit.prevent="(edit == 'false') ? saveDesignation() : designationUpdate()" -->
          <div class="modal-body">
            <form @submit.prevent="edit == 'false' ? saveCategory() : categoryUpdate()">
              <div class="row">
                <div class="col-12" v-if="msgImgError">
                  <div class="alert alert-danger p-1" role="alert">
                    <small>{{ msgImgError }}</small>
                  </div>
                </div>
                <div class="col-xl-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px">
                  <div id="preview">
                   
                    <label for="file-input2">
                      <img
                      style="width: 50px; height: 50px"
                      v-if="logoPreImg"
                      :src="logoPreImg"
                    />
                      </label>
                  </div>
                  <div class="form-group">
                    <div class="image-upload" style="padding: 5px 10px 5px 10px">
                    
                      <input
                        class="input-file"
                        @change="logoUpload"
                        style="position: absolute; top: -150000px"
                        id="file-input2"
                        type="file"
                        accept="image/*"
                     
                      />
                    </div>
                  </div>
                </div>

                <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                  <div class="form-group">
                    <lable><strong>Name <samll style="color:#f21000">*</samll></strong></lable>
                    <input
                      required
                      style="height: 30px"
                      class="form-control"
                      placeholder="Name"
                      v-model="form.name"
                      @change="lenthheker($event, 'catName')"
                    />
                    <span style="color: #f21000" id="catName"></span>
                    <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.name">{{
                          form.errors.error.name[0]
                        }}</span></span
                      ></small
                    >
                  </div>
                </div>

                <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                  <div class="form-group">
                    <lable><strong>Status <samll style="color:#f21000">*</samll></strong></lable>
                    <!-- <input style="eight:30px;" type="text" class="form-control" placeholder="description" > -->
                    <select
                    required
                      style="height: 30px; padding-top: 3px"
                      class="form-select"
                      aria-label="Default select example"
                      v-model="form.status"
                    >
                      <option value="true" selected>Active</option>
                      <option value="false">InActive</option>
                    </select>
                    <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.status">{{
                          form.errors.error.status[0]
                        }}</span></span
                      ></small
                    >
                  </div>
                </div>
                <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                  <div class="form-group">
                    <lable><strong>Description</strong></lable>
                    <textarea
                      style="text-transform: uppercase"
                      class="form-control"
                      rows="3"
                      placeholder="remark"
                      v-model="form.description"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn text-white btn-sm"
                  style="background-color: red"
                  data-bs-dismiss="modal"
                >
                  <b>Close</b>
                </button>
                <button
                  type="submit"
                  class="btn text-white btn-sm"
                  style="background-color: #00364f"
                >
                  <b>Confirm</b>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal addons  -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="categoryServices"
      tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">
              <strong style="color: #00364f">Services</strong>
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="container-fluid table-scroll">
                  <table class="table table-hover table-sm">
                    <tr
                      class="text-white tr-head table-wrapper-scroll-y rounded-circle"
                      style="background-color: #00364f"
                    >
                      <th style="width: 10%">+</th>
                      <th style="width: 10%">Code</th>
                      <th style="width: 80%">Services</th>
                    </tr>
                    <tbody>
                      <tr v-for="(service, index) in services" :key="index">
                        <td>
                          <!-- <span v-if="service.id == otherPackageServices">
                            hi
                        </span>
                         <span v-else>
                           by
                        </span> -->
                          <input
                            @click="addNewServiceInCategory(service, $event)"
                            class="form-check-input"
                            type="checkbox"
                            :value="service.id"
                            v-model="catServices"
                          />
                        </td>
                        <td>
                          <label
                            for="flexCheckDefault"
                            style="color: #00364f; font-weight: 600"
                            v-if="service"
                          >
                            {{ service.code }}</label
                          >
                        </td>
                        <td>
                          <label
                            for="flexCheckDefault"
                            style="color: #00364f; font-weight: 600"
                            v-if="service"
                          >
                            {{ service.name }}</label
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm text-white"
              style="background-color: #f21000"
              data-bs-dismiss="modal"
            >
              <strong> Close</strong>
            </button>
            <!-- <button
              type="button"
              class="btn btn-sm text-white"
              style="background-color: #00364f"
            >
              <strong>Confirm</strong>
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

//
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "Categories",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      msgImgError: "",
      categoryservice: [],
      catServices: [],
      category_id: null,
      categoryService_Form: {
        service_category_id: null,
        service_id: null,
        remark: "",
      },
      services: [],
      extensionLogo: "",
      logoPreImg: this.$store.state.placeholderImg,
      form: {
        name: "",
        shortName: "",
        shortDescription: "",
        description: "",
        icon: "",
        logo: "",
        status: "true",
        remark: "",
        errors:{}
      },
      edit: "false",
      cat_id: null,
      loading: false,
      categories: [],
    };
  },
  methods: {
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadCategories();
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "catName") {
        if (data.length < 3) {
          this.submitStatus = "true";
          $("#catName").html("<small>Category Name required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.submitStatus = "false";
          $("#catName").html("<small></small>");
        }
      }
    },
    newHeading() {
      this.logoPreImg = this.$store.state.placeholderImg;
      this.form = {};
      this.edit = "false";
      this.form.status = "true";
    },
    logofinder(category) {
      var photo = this.$store.state.placeholderImg;
if(category.logo){

  if (
        this.$store.state.imgUrl +
        "/servicecategory/" +
        category.id +
        "/thumbs/" +
        category.logo
      ) {
        photo =
          this.$store.state.imgUrl +
          "/servicecategory/" +
          category.id +
          "/thumbs/" +
          category.logo;
        return photo;
      }
} else {
        photo = this.$store.state.placeholderImg;
        return photo;
      }
    },
    logoUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Logo Image Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#file-input2").value;
        this.extensionLogo = fileName.split(".").pop();
        if (
           this.extensionLogo == "png" ||
          this.extensionLogo == "jpeg" ||
          this.extensionLogo == "jpg" ||
          this.extensionLogo == "PNG" ||
          this.extensionLogo == "JPEG" ||
          this.extensionLogo == "JPG" 
        ) {
          this.logoPreImg = URL.createObjectURL(e.target.files["0"]);
          this.form.logo = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG , PNG, JPEG";
        }
      }
    },

    categoryUpdate() {
      if (this.msgImgError == "") {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        this.loading = true;
        this.$axios
          .post(`superadmin/servicecategory/${this.form.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Category Updated Succesfully", {
              autoClose: 1000,
            });
            $("#exampleModal").modal("hide");
            this.loadCategories();
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.form.errors = error.response.data;
          });
      }
    },
    categoryEdit(value) {
      this.logoPreImg = this.$store.state.placeholderImg;
      this.form = {};

      this.form.description = value.description;
      this.form.id = value.id;
      this.form.name = value.name;
      this.form.status = value.status;
      this.form.shortName = value.shortName;
      this.form.code = value.code;

      console.log(this.form);
      this.cat_id = value.id;
      if (value.logo != null) {
        if (
          this.$store.state.imgUrl +
          "/servicecategory/" +
          value.id +
          "/thumbs/" +
          value.logo
        ) {
          this.logoPreImg =
            this.$store.state.imgUrl +
            "/servicecategory/" +
            value.id +
            "/thumbs/" +
            value.logo;
        }

        if (
          this.$store.state.imgUrl +
          "/servicecategory/" +
          value.id +
          "/" +
          value.logo
        ) {
          this.logoPreImg =
            this.$store.state.imgUrl + "/servicecategory/" + value.id + "/" + value.logo;
        }
      } else {
        this.logoPreImg = this.$store.state.placeholderImg;
      }

      this.edit = "true";
    },

    saveCategory() {
      if (this.msgImgError == "") {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        this.loading = true;
        this.$axios
          .post("superadmin/servicecategory", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("New Category Create Succesfully", {
              autoClose: 1000,
            });
            $("#exampleModal").modal("hide");
            this.loadCategories();
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.form.errors = error.response.data;
          });
      }
    },
    categoryDelete(category) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`superadmin/servicecategory/${category.id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("Category Deleted Succesfully", {
                  autoClose: 1000,
                });
                this.loadCategories();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
               
                
                console.log(error.response.data)
            this.$swal.fire({
              confirmButtonColor: "#00364f",
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });



              });
          }
        });
    },

    loadCategories(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `superadmin/servicecategory?per_page=${this.per_page}`;
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.categories = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    states() {
      this.loading = true;
      this.$axios
        .get("superadmin/state?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.allstates = res.data.data.data;
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          error;
        });
    },
    addNewServiceInCategory(serviceValues, event) {
      this.categoryService_Form.service_category_id = this.category_id;
      this.categoryService_Form.service_id = serviceValues.id;
      if (event.target.checked == true) {
        this.loading = true;
        this.$axios
          .post("superadmin/servicecategoryrelation", this.categoryService_Form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Category Service Saved Succesfully", {
              autoClose: 1000,
            });
            this.getServices(this.category_id);
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            error;
          });
      } else {
        for (let i = 0; i < this.categoryservice.length; i++) {
          if (
            this.categoryservice[i].service_id == this.categoryService_Form.service_id
          ) {
            // alert('delete')
            this.loading = true;
            this.$axios
              .delete(
                `superadmin/servicecategoryrelation/${this.categoryservice[i].id}`,
                {
                  headers: { Authorization: "Bearer " + localStorage.accessToken },
                }
              )
              .then((res) => {
                res;
                toast.success("Category Service Deleted Succesfully", {
                  autoClose: 1000,
                });
                this.getServices(this.category_id);
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                error;
              });
          }
        }
      }
    },
    getServices(category) {
      this.$axios
        .get("superadmin/service?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.services = res.data.data;
        });

      if (category) {
        this.category_id = category;
        this.loading = true;
        this.$axios
          .get(
            `superadmin/servicecategoryrelation?service_category_id=${category}&all=all`,
            {
              headers: { Authorization: "Bearer " + localStorage.accessToken },
            }
          )
          .then((res) => {
            this.categoryservice = res.data.data;
            this.catServices = [];
            for (var i = 0; i < this.categoryservice.length; i++) {
              this.catServices.push(this.categoryservice[i].service_id);
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.category
    console.log(this.$store.state.superAdminPageTitles.category)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadCategories();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}




.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
